@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Vesper+Libre:wght@500&display=swap');

.App {
  background-color: #f1f1f1;
}

.Header {
  background-color: #f1f1f1;
  padding: 50px;
  padding-bottom: 150px;
}

h1 {
  font-size: 3.5em;
  font-weight: bold;
  margin: 0;
  padding: 10px;
  font-family: 'Nunito', sans-serif;
  color: white;
}

.Header h1 {
  color: #505050;
}

p {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5em;
  margin: 0;
  padding: 10px;
  color: #ffffff
}

.TopBarButton {
  border-bottom: 2px solid #505050;
  color: #505050;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  font-family: 'Nunito', sans-serif;
  text-decoration: none;
}

.About{
  background-color: #3c5695;
  padding: 50px;
  padding-bottom: 100px;
}

.Projects{
  background-color: #678ba4;
  padding: 50px;
  padding-bottom: 100px;
}

.Skills{
  background-color: #369b9b;
  padding: 50px;
  padding-bottom: 100px;
}

.Resume{
  background-color: #54a871;
  padding: 50px;
  padding-bottom: 100px;
}

.Contact{
  background-color: #98ba5d;
  padding: 50px;
  padding-bottom: 100px;
}

.footer {
  color: black;
}

.Card {
  background-color: #979797;
  padding: 50px;
  padding-bottom: 100px;
  border-radius: 10px;
  margin: 20px;
  position: relative;
}

.Cards-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Card p{
  font-size: 1em;
}

.Card h1{
  font-size: 3em;
}

.CardCover{
  background-color: #404070;
  width: 160px;
  height: 160px;
  margin: -20px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  transition: all 0.25s ease-in-out;
}